
.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

}

.full-bg-img {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.bg-jem-green {
  background-color: $jem-green-forest;
}

.bg-eggshell {
  background-color: $eggshell !important;
}