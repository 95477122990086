%font-awesome-free {
    font-family: "Font Awesome 5 Free" !important;
}

%base-input {
    padding: 10px 15px;
    height: unset; // pretects against form controls
    font-family: $p-font-family;
    font-size: $input-font-size;
    color: $input-font-color;
    background: #fff;
    border-style: solid;
    border-color: $input-border-color;
    border-radius: $input-corner-rad;
    border-width: 1px;
    transition: $all-2;

    &:placeholder {
        color: $placeholder-color;
    }

    &:focus,
    &:active {
        border-color: $jem-green;
        outline: none !important;
        box-shadow: none;
    }
}

label {
    font-size: $input-font-size;
    color: $input-font-color;
}

input,
.form-control,
.input-group > .form-control {
    @extend %base-input;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
    border-top-right-radius: $input-corner-rad;
    border-bottom-right-radius: $input-corner-rad;
}

/* set base select styles */
select {
    @extend %base-input;
    cursor: pointer;
}

/* override el-select */
.el-select .el-input.is-focus .el-input__inner,
.el-select .el-input__inner:focus {
    border-color: $jem-green !important;
}

/* override invalid input styles*/
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    border: 1px solid $jem-red !important;
    box-shadow: none !important;
}

.is-invalid {
    border-color: $jem-red !important;
}

.is-disabled {
    background-color: $off-white !important;
}

.el-select.is-invalid {
    border: 1px solid $jem-red !important;
    border-radius: 5px;
}

.el-select.is-invalid ~ .invalid-feedback {
    display: block !important;
}

.invalid-feedback {
    margin-top: 5px;
    padding-left: 15px;
    font-size: $sm-font-size;
    color: $jem-red;
}

/*********************/
/* custom checkboxes */
/*********************/

/* modified from https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_custom_checkbox */

.jem-checkbox-wrap {
    cursor: pointer;
    user-select: none;
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 25px;
    font-size: 14px;

    input {
        /* Hide browser default checkbox */
        cursor: pointer;
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }
}

/* custom checkbox */
.jem-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 3px; /* push checkbox down */
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid $light-grey;
    border-radius: $input-corner-rad;
    transition: $all-2;

    &:hover {
        border: 1px solid $jem-green;
    }
}

/* If checkbox is checked, add blue background */
.jem-checkbox-wrap input:checked ~ .jem-checkmark {
    background-color: #fff;
    //border-radius: $b-rad-2;

    &:after {
        /* Show the jem-checkmark if checked */
        display: block;
    }
}

/* jem-checkmark/indicator (hidden if not checked) */
.jem-checkmark:after {
    display: none;
    content: "";
    position: absolute;
}

/* Style the jem-checkmark/indicator */
.jem-checkbox-wrap .jem-checkmark:after {
    top: 3px;
    left: 6px;
    width: 5px;
    height: 10px;
    border: solid $jem-green;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

/* Change the color of the active switch */
.el-switch.is-checked .el-switch__core {
    background-color: $jem-green !important;
    border-color: $jem-green !important;
}

.el-switch__label.is-active {
    color: $jem-green !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: $jem-green !important;
    border-color: $jem-green !important;
}

.el-checkbox__input.is-checked+.el-checkbox__label {
    color: $jem-green !important;
}

/*************************/
/* end custom checkboxes */
/*************************/

/*********************/
/* react datepicker */
/*********************/

.react-datepicker-wrapper {
    display: block !important;
}