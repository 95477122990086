
/* BS4 tooltip overrides */

.tooltip > .tooltip-inner {
  font-size: $tt-font-size;
  color: $body-color;
  background: #fff;
  border: $tt-border;
  border-radius: $tt-b-rad;
  box-shadow: $btn-shadow;
}

.tooltip .arrow::before {
  border-top-color: transparent;
  border-bottom-color: transparent;
}