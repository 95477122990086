
.modal-wrapper {

  .card {
    border-top-left-radius: 5px;
  }

  .modal-scroll {
    max-height: 275px;
    overflow-y: auto;
  }
}