

/* regular
@font-face {

} */

/* bold
@font-face {

}*/

.header-fixed .app-body {
  margin-top: 0;
}


// set base sizes
body {
  background-color: $page-background;
}

html,
body {
  font-family: $p-font-family;
  font-size: $p-font-size;
  color: $body-color;
}

h1 {
  margin-bottom: 0;
  font-family: $h1-font-family;
  font-size: $h1-font-size;
  font-weight: $h1-font-weight;
  color: $body-color;

  @media only screen and (max-width: 768px) {
    font-size: 26px;
  }
}

h2 {
  margin-bottom: 0;
  font-family: $h2-font-family;
  font-size: $h2-font-size;
  font-weight: $h2-font-weight;
  color: $body-color;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
}

h3 {
  margin-bottom: 0;
  font-family: $h3-font-family;
  font-size: $h3-font-size;
  font-weight: $h3-font-weight;
  color: $body-color;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
}

p {
  font-family: $p-font-family;
  font-size: $p-font-size;
  font-weight: $p-font-weight;
}

a {

  &:hover {
    text-decoration: none;
  }
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-dark-grey {
  color: $dark-grey !important;
}

.text-blue {
  color: $jem-blue !important;
}

.text-lime {
  color: $jem-lime !important;
}

.text-green {
  color: $jem-green !important;
}

.text-yellow {
  color: $jem-yellow !important;
}

.text-bold {
  font-weight: 700 !important;
}