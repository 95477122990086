

/************/
/*  Colors  */
$jem-blue:          #45b1f2; // Primary CTA blue
$darker-blue:       #3ca2df; // Hover blue

$jem-green:         #1F7055; // Primary CTA blue
$darker-green:      #195B45; // Hover blue
$jem-green-forest:  #144A38; // Dark green

$jem-bright-green:  #63cb22; // green Status indicator

$jem-lime:          #dbe641; // Accent Green
$darker-lime:       #c0ca34; // Hovers
$darkest-lime:      #8b9413; // 

$jem-yellow:        #dea11f; // yellow status indicator

$jem-black:         #454545; // Body text
$light-grey:        #dcdcdc; // Borders
$off-white:         #fcfcfc; // active btn, disabled field bg
$dark-grey:         #718096; // Placeholder text, table titles, small  // Testing, remove later
$eggshell:          #EEEDE4; // Page background
$light-mint:        #F3F8F7; // 

$jem-red:           #d73838; // Delete

$text-neutral:      #171923; // Neutral text

$border:            #CBD5E0; // Borders

$bg-default:        #FFFFFF;
$page-background:   #f9f9f9; // page backing

$secondary-hover:   #EDF2F7;

$disabled-bg:       #FFFFFF;
$disabled-content:  #A0AEC0;
/*******************************************************/


/***********/
/*  Fonts  */
$body-font:           'Geist', sans-serif;
$body-color:          $jem-black;

/* <h1> */
$h1-font-size:        24px;
$h1-font-weight:      700;
$h1-font-family:      $body-font;
$h1-color:            $jem-black;

/* <h2> */
$h2-font-size:        20px;
$h2-font-weight:      700;
$h2-font-family:      $body-font;
$h2-color:            $jem-black;

/* <h3> */
$h3-font-size:        18px;
$h3-font-weight:      700;
$h3-font-family:      $body-font;
$h3-color:            $jem-black;

/* <th> */
$th-font-size:        14px;
$th-font-weight:      700;
$th-font-family:      $body-font;
$th-color:            $dark-grey;

/* <p> */
$p-font-size:        14px;
$p-font-weight:      400;
$p-font-family:      $body-font;
$p-color:            $jem-black;

/* <small> */
$sm-font-size:        12px;
$sm-font-weight:      400;
$sm-font-family:      $body-font;
$sm-color:            $dark-grey;
/*********************************/


/************/
/*  inputs  */
$input-font-size:            14px;
$input-font-color:           $jem-black;
$input-border-color:         $light-grey;
$input-corner-rad:           8px;

$placeholder-color:          $dark-grey;
$placeholder-size:           14px;
/*********************************/


/*************/
/*  buttons  */
$btn-font-size:         14px;
/*********************************/




/*****************/
/*  box-shadows  */
$btn-shadow:                    0 2px 5px 0 rgba(47, 53, 56, 0.2); // primary site shadow
$secondary-btn-shadow:          0 2px 5px 0 rgba(47, 53, 56, 0.1); // secondary buttons
/*********************************/



/**************/
/*  tooltips  */
$tt-font-size:        12px;
$tt-b-rad:            2px;
$tt-border:           1px solid $light-grey;
$tt-box-shadow:       $btn-shadow;
/*********************************/


/*****************/
/*  transitions  */
$all-2:           0.2s all ease-in-out;
$all-3:           0.3s all ease-in-out;
$all-4:           0.4s all ease-in-out;
$all-5:           0.5s all ease-in-out;