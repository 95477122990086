// Plugins

// FontAwesome 5
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';

// Header Styles
.demo,
.logged-in-as {
  margin: 0 !important;
  border-radius: 0 !important;
}

.tooltip-inner {
  min-width: 300px !important;
  padding-top: 20px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
