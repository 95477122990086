
%font-awesome-free {
  font-family: 'Font Awesome 5 Free' !important;
}

%active-indicator {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 3px;
}

.material-icons {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons';
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}




/******************/
/* top nav styles */
.jem-navbar {
  padding: 0;
  background-color: #fff;
  box-shadow: $secondary-btn-shadow;

  .jem-nav-hover {

    .nav-link {
      padding: 25px 15px !important;
      color: $dark-grey;
      font-weight: 700;
      background-color: #fff;
      transition: $all-2;

      &:hover {
        color: $light-mint;
        background-color: $jem-green;
      }
    }
  }

  .dropdown-menu {
    border: none;
  }

  .dropdown-item {
    color: $dark-grey;
    font-weight: 700;
    border-bottom: none;
  }
}

/******************/





/**********************/
/* Sidebar nav styles */
.jem-sidebar {
  background-color: #fff;
  box-shadow: $btn-shadow;

  .nav-link {
    padding: 10px 15px;
    color: $dark-grey;
    font-weight: 700;

    .nav-icon {
      margin-right: 15px;
      width: 25px;
      color: $light-grey;
      font-size: 1.3333333333em; // fa-lg
    }

    &.active {
      color: $light-mint;
      background-color: $jem-green;

      &:after {
      }

      .nav-icon {
        color: $light-mint;
      }

      &:hover {
        color: $light-mint;
        background-color: $jem-green;

        .nav-icon {
          color: $light-mint;
        }
      }
    }

    &:after {
      background-color: transparent;
      transition: $all-2;
    }

    &:hover {
      color: $jem-green;
      background-color: transparent;

      .nav-icon {
        color: $jem-green;
      }

      &:after {
        background-color: $jem-green;
      }
    }
  }

  .nav-dropdown {
    &.open {
      .nav-dropdown-toggle {
        color: $jem-green !important;
        background-color: $light-mint !important;
      }

      background-color: $light-mint;

      .nav-link {
        color: $jem-green;
      } .nav-icon {
        color: $jem-green;
      }

      .nav-dropdown-items {
        background: $light-mint;

        .open {
          .nav-link {
            color: $light-mint;
            background: $jem-green;
          }
        }
      }

      .nav-dropdown-items .nav-link {
        color: $dark-grey;

        .nav-icon {
          color: $dark-grey;
        }

        &.active{
          color: $light-mint !important;
        }

        &:hover {
          color: $jem-green;
        }
      }

      &:hover {
        .nav-link .nav-icon {
          color: $jem-green;
        }
      }
    }
  }
}
/*********************************/




/**************/
/* tab styles */
.tabs-component {
  margin-top: 0;
  margin-bottom: 0;

  .tabs-component-tab {
    transform: translateY(0px);
    margin-bottom: 0;
    margin-right: 0;
    color: $dark-grey;
    border-radius: 0;
    background-color: #fff;
    transition: $all-2;

    &:after {
      background-color: transparent;
      transition: $all-2;
    }

    &:hover {
      &:after {
        background-color: $jem-green;
      }
    }

    &:not(first-child) {
      border-right: 1px solid $light-grey;
    }

    &:first-child {
      border-top-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
    }
  }

  a {
    padding: 10px 15px;

    &:hover {
      color: $jem-green;
      background-color: $light-mint;
      text-decoration: none;
    }
  }
}

.tabs-component {
  .tabs-component-tabs {
    padding-left: 0;
    margin-bottom: 0;

    .is-active {
      color: $light-mint;
      background-color: $jem-green;
      border: 1px solid $jem-green;
  
      a {
        &:hover {
          color: $light-mint;
          background-color: $jem-green;
        }
      }
  
    }
  }
}
/*********************************/



/**********************/
/* breadcrumb styles  */
.jem-breadcrumb {

  li {
    font-weight: $h3-font-weight !important;
    color: $dark-grey !important;

    a {
      color: $dark-grey !important;

      &:hover {
        color: $dark-grey !important;
        text-decoration: none;
      }
    }
  }

  .breadcrumb-item.active {
    color: $jem-green !important;
  }

  .breadcrumb-item + .breadcrumb-item {
    &:before {
      @extend %font-awesome-free;
      padding-left: 20px;
      padding-right: 20px;
      color: $light-grey;
      content: '\f054' !important;
      font-weight: 900 !important;
    }
  }

  /*
   * instances where a dropdown appears amongst
   * top nav breadcrumbs
   */
  .btn {
    &.dropdown-toggle {
      color: $jem-green !important;
      font-size: 14px;
      font-weight: 900;
    }
  }
}
/*********************************/





/*************************/
/* slick slide overrides */
.evo-carousel {

  .slick-next,
  .slick-prev {
    height: 35px;
    width: 35px;
  }

  .slick-prev {
    left: -30px;

    @media only screen and (max-width: 768px) {
      left: -10px;
    }

    &:before {
      @extend .material-icons;
      display: flex;
      justify-content: center;
      width: 35px;
      content: '\e5cb';
      color: grey;
      font-size: 54px;
      line-height: 34px;
    }
  }

  .slick-next {
    right: -32px;

    @media only screen and (max-width: 768px) {
      right: -11px;
    }

    &:before {
      @extend .material-icons;
      display: flex;
      justify-content: center;
      width: 35px;
      content: '\e5cc';
      color: grey;
      font-size: 54px;
      line-height: 34px;
    }
  }
}

.slick-slider {
  //background: url('~slick-carousel/slick/ajax-loader.gif');
}

.slick-slider .slick-list { 
  opacity: 0; 
  position: absolute; 
}

.slick-slider.slick-initialized {
  background: none;
}

.slick-slider.slick-initialized .slick-list { 
  opacity: 1;
  position: relative;
  transition: opacity 0.2s ease-in;
}
/*********************************/





/*********************************/
/* bs4 accordion style overrides */
[data-toggle="collapse"].collapsed .accordion-nav-arrow {
	transition: 0.2s all ease-in-out;
	transform: rotate(0deg);
	color: #fff;
}

[data-toggle="collapse"] .accordion-nav-arrow {
	transform: rotate(90deg);
	color: #fff;
}

.accordion-nav-arrow {
	margin-bottom: 1px;
	transform: rotate(0deg);
	color: #fff;
	transition: 0.2s all ease-in-out;
}


/******************/
/* action nav bar styles */
.action-bar {
  margin: -1.5rem 0 1.5rem 0;
  padding: 0 0.75rem;
  gap: 1rem;
  border-bottom: 1px solid #ddd;
  justify-content: space-between;

  .page-title {
    font-weight: $h3-font-weight !important;
    color: #171923;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
  }

  .tabs {
    display: flex;
    min-height: 60px;
  }

  .tab-button {
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    background: #fff;
    align-content: center;
    color: #171923;
  }

  .tab-button.active {
    background-color: #00582e;
    color: white;
  }

  .buttons {
    display: flex;
    gap: 1rem;
  }

  .btn {
    font-weight: 600;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
  }

  .btn.preview {
    background-color: white;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 8px;
  }

  .btn.save {
    background-color: #00582e;
    color: white;
    border-radius: 8px;
  }
}
/******************/
/* side bar styles */
/* Change arrow direction */
.nav-dropdown-toggle::before {
  content: '\f105' !important;
  font-family: "Font Awesome 5 Free";
  background-image: none !important;
  margin-top: -8px !important;
}

/* Arrow color on hover or active */
.nav-dropdown-toggle:hover::before,
.nav-dropdown-toggle:focus::before {
  color: $jem-green !important; 
}

/* Arrow color when active */
.nav-dropdown.open .nav-dropdown-toggle::before {
  transform: rotate(90deg) !important;
  color: $jem-green !important;
  margin-top: -4px !important;
}


.el-collapse-item__content {
  padding-bottom: 0 !important;
}