%primary-btn {
  cursor: pointer;
  padding: 10px 15px;
  color: #fff;
  font-size: $btn-font-size;
  font-weight: 700 !important;
  background-color: $jem-green;
  border: none;
  border-radius: 8px !important;
  box-shadow: $btn-shadow;
  transition: $all-2;
}

%danger-btn {
  cursor: pointer;
  padding: 10px 15px;
  color: #fff;
  font-size: $btn-font-size;
  font-weight: 700;
  background-color: $jem-red;
  border: none;
  border-radius: 8px;
  box-shadow: $btn-shadow;
  transition: $all-2;
}

%secondary-btn {
  cursor: pointer;
  padding: 10px 15px;
  color: $jem-green;
  font-size: $btn-font-size;
  font-weight: 700;
  background-color: #fff;
  border: none;
  border-radius: 8px !important;
  box-shadow: $secondary-btn-shadow;
  transition: $all-2;
}

%icon-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 10px;
  background-color: white;
  border: none;
  border-radius: 5px;
  transition: $all-2;

  &:hover {
    box-shadow: $btn-shadow;
    text-decoration: none;
  }
}

/*
 * a blue button with white text and a
 * box shadow. It features a grey disabled
 * styling
 */
.jem-btn-primary {
  @extend %primary-btn;

  &:hover {
    color: #fff !important;
    text-decoration: none !important;
    background-color: $darker-green !important;
  }

  &:disabled {
    cursor: not-allowed;
    color: $dark-grey !important;
    background-color: $light-grey !important;
  }

  &:active,
  &:focus {
    color: #fff !important;
    text-decoration: none !important;
    box-shadow: none !important
  }
}

.form-btn-primary {
  @extend %primary-btn;

  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: $darker-green;
  }

  &:disabled {
    cursor: not-allowed;
    color: $dark-grey;
    background-color: $light-grey;
  }

  &:active,
  &:focus {
    color: #fff;
    text-decoration: none;
    box-shadow: none
  }
}

.btn-primary {
  border: none;
  background-color: $jem-green;
  color: #fff;

  &:hover {
    color: #fff !important;
    text-decoration: none !important;
    background-color: $darker-green !important;
  }

  &:disabled {
    cursor: not-allowed;
    color: $dark-grey !important;
    background-color: $light-grey !important;
  }

  &:active,
  &:focus {
    color: #fff !important;
    text-decoration: none !important;
    box-shadow: none !important
  }
}

/*
 * a white button with green text and a
 * box shadow.
 */
.jem-btn-secondary {
  @extend %secondary-btn;

  &:hover {
    color: $jem-green !important;
    background-color: $off-white !important;
  }

  &:active,
  &:focus {
    color: $jem-green !important;
    box-shadow: none !important
  }
}

/*
 * a white button with black text
 */
 .jem-btn-secondary-black {
  @extend %secondary-btn;

  color: #171923 !important;
  border: 1px solid #CBD5E0;

  &:hover {
    background-color: $off-white !important;
  }

  &:active,
  &:focus {
    color: $jem-green !important;
    box-shadow: none !important
  }
}

/*
 * a white button with blue text and a
 * box shadow.
 */
 .jem-btn-danger {
  @extend %danger-btn;

  &:hover {
    color: $jem-red !important;
    background-color: $off-white !important;
  }

  &:active,
  &:focus {
    color: $jem-red !important;
    box-shadow: none !important
  }
}


/*
 * a white button with blue fa icon
 * that has a box shadow on hover.
 * Typecast as a "table action"
 */
.blue-icon-btn {
  @extend %icon-btn;
  color: $jem-blue !important;

  &:hover,
  &:focus,
  &:active {
    color: $jem-blue !important;
  }
}

.blue-icon-btn-no-hover {
  @extend %icon-btn;
  color: $jem-blue !important;

  &:hover,
  &:focus,
  &:active {
    color: $jem-blue !important;
    box-shadow: none;
  }
}

.green-icon-btn {
  @extend %icon-btn;
  color: $jem-green !important;

  &:hover,
  &:focus,
  &:active {
    color: $jem-green !important;
  }
}

.green-icon-btn-no-hover {
  @extend %icon-btn;
  color: $jem-green !important;

  &:hover,
  &:focus,
  &:active {
    color: $jem-green !important;
    box-shadow: none;
  }
}

.red-icon-btn {
  @extend %icon-btn;
  color: $jem-red !important;

  &:hover,
  &:focus,
  &:active {
    color: $jem-red !important;
  }
}

.el-dropdown {
  .el-button--default {
    @extend %primary-btn;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    
    .el-icon-arrow-down {
      color: #fff !important;
      font-size: 12px;
    }

    &:hover,
    &:focus {
      color: #fff !important;
      background-color: $darker-green !important;
      border-color: transparent !important;
      
      .el-icon-arrow-down {
        color: #fff !important;
      }
    }

    &:active {
      color: #fff !important;
      background-color: $jem-green !important;
    }

    &.is-disabled {
      cursor: not-allowed;
      color: $dark-grey !important;
      background-color: $light-grey !important;
      
      .el-icon-arrow-down {
        color: $dark-grey !important;
      }
    }
  }

  .el-button--secondary {
    @extend %secondary-btn;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    border: 1px solid $border !important;
    color: $text-neutral;
    box-shadow: none !important;
    .el-icon-arrow-down {
      color: $text-neutral !important;
      font-size: 12px;
    }

    &:hover,
    &:focus {
      border: 1px solid $border !important;
      color: $text-neutral !important;
      background-color: $secondary-hover !important;
      .el-icon-arrow-down {
        color: $text-neutral !important;
      }
    }

    &.is-disabled {
      cursor: not-allowed;
      color: $disabled-content !important;
      background-color: $disabled-bg !important;
      .el-icon-arrow-down {
        color: $disabled-content !important;
      }
    }
  }
}

.el-dropdown-menu {
  padding: 8px !important;
  border-radius: 8px !important;
  box-shadow: $btn-shadow !important;
  border: none !important;

  .el-dropdown-menu__item {
    padding: 8px 16px !important;
    border-radius: 4px !important;
    color: $text-neutral !important;
    font-size: $btn-font-size !important;
    line-height: 1.5 !important;

    &:hover,
    &:focus {
      background-color: $secondary-hover !important;
      color: $text-neutral !important;
    }

    &.is-disabled {
      cursor: not-allowed !important;
      color: $light-grey !important;
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }
  }

  .popper__arrow {
    display: none !important;
  }
}

.el-button--primary {
  @extend %primary-btn;
  background-color: $jem-green !important;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  border: none !important;
  
  i {
    color: #fff !important;
    font-size: 12px;
  }

  &:hover,
  &:focus {
    color: #fff !important;
    background-color: $darker-green !important;
    border-color: transparent !important;
    text-decoration: none !important;
    
    i {
      color: #fff !important;
    }
  }

  &:active {
    color: #fff !important;
    background-color: $jem-green !important;
    box-shadow: none !important;
  }

  &.is-disabled,
  &.is-disabled:hover,
  &.is-disabled:focus {
    cursor: not-allowed;
    color: $dark-grey !important;
    background-color: $light-grey !important;
    border-color: transparent !important;
    
    i {
      color: $dark-grey !important;
    }
  }
}

/* New el-button styles */
