// Create Candidate Styles

.candidate-spinner {
  position: absolute;
  top: 50%;
  z-index: 999;
  left: 50%;

  .spinner-border {
    width: 4rem;
    height: 4rem;
  }
}
