@import '../global';

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Bootstrap Overrides
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Pages
@import 'pages/dashboard';
@import 'pages/candidates';

// Site variables
@import 'site-variables';

// Site elements
@import 'base-elements/base-elements';

// Site variables
@import '../frontend/site-variables';

// Import styles
@import '~@coreui/coreui/scss/coreui';

// Site elements
@import '../frontend/base-elements/base-elements';

// Form render styles
@import 'form-render-styles';