// .form-render-container {
    .react-form-builder-form {
        h3 {
            font-size: 20px !important;
            font-weight: 700 !important;
            line-height: 26px !important;
            padding: 10px !important;
        }
        .rfb-item .row {
            border: 0px solid #fff !important;
            padding: 10px 0 !important;
        }

        .rfb-item {
            padding: 0px !important;
            margin: 10px 0 !important;
        }

        .form-group .form-label span {
            padding: 0px !important;
        }

        canvas {
            border: 1px solid #dcdcdc;
            border-radius: 8px;
            margin-right: 5px;
            display: inline-block;
        }

        .rfb-item ul, .rfb-item ol {
            padding-inline-start: 40px;
        }

        .rfb-item img:has(~ .clear-signature) {
            max-width: 300px !important;
        }
    }
// }