
%active-indicator {
  content: "";
  position: absolute;
  bottom: -1px;
  width: 100%;
  height: 3px;
}


table,
.table {
  thead {
    th {
      font-size: $th-font-size;
      font-weight: $th-font-weight;
      font-family: $th-font-family;
      color: $th-color;
      border-top: none;
      border-bottom: none;
    }
  }

  tbody {
    td {
      vertical-align: middle !important;
      border-top: 1px solid $light-grey;
    }
  }
}
/******************/



/******************/
/* paginatedtable */

.jem-paginated-table {
  .jem-table-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {

    }

    select {
      
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      font-weight: 700;
      color: $dark-grey;
      transition: $all-2;

      &:after {
        @extend %active-indicator;
        background-color: transparent;
        transition: $all-2;
      }

      &:hover {
        background-color: #fff;

        &:after {
          @extend %active-indicator;
          background-color: $darker-lime;
        }
      }
    }

    .disabled {
      cursor: not-allowed;
    }

    .page-item {
      &.disabled {
        .page-link {
          color: $dark-grey;
        }
      }

      &:first-child {
        .page-link {
          border-top-left-radius: $input-corner-rad;
          border-bottom-left-radius: $input-corner-rad;
        }
      }

      &:last-child {
        .page-link {
          border-top-right-radius: $input-corner-rad;
          border-bottom-right-radius: $input-corner-rad;
        }
      }
    }

    .page-link {
      border-color: $light-grey;
    }

    > .active {

      .page-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        color: $light-mint;;
        background-color: $jem-green;
        border-color: $jem-green;

        &:after {
          @extend %active-indicator;
          background-color: $darker-green;
        }

        .disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}

.el-table__row {
  .cell {
    color: $text-neutral;
  }
}

.pagination {
  .page-link {
    color: $jem-green;
    &:hover {
      color: $jem-green;
    }
  }
  .page-item.active {
    color: $off-white;
    .page-link {
      background-color: $jem-green;
      border-color: $jem-green;
      &:hover {
        color: $off-white;
      }
    }
  }
  
}