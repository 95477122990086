/* override bs 4 card class */

%card {
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid $light-grey;
}

.card {
    @extend %card;
    border-top-left-radius: 0;
}

.jem-card {
    @extend %card;
}

.timeline-row {
    padding-bottom: 15px;

    .timeline-icon {
        &:after {
            content: "";
            display: flex;
            flex-direction: column;
            width: 2px;
            height: calc(100% - 20px);
            margin: 0 auto;
            background: $jem-green;
        }
    }

    .timeline-indicator {
        height: 35px;
        width: 35px;
        background-color: $jem-green;
        border-radius: 50%;
    }
}

.timeline-card {
    min-width: 400px;
    max-width: 400px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid $dark-grey;
}
